/* Scope the styles to the .rsvp-redirect container */
.rsvp-redirect-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    text-align: center;
    line-height: 1.5;
    background-color: #f5dfad;
}

.rsvp-redirect-container {
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.rsvp-redirect-container h1 {
    font-size: 2em;
    color: #143C5A;
}

.rsvp-redirect-container p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.rsvp-redirect-loader {
    border: 4px solid #143C5A;
    border-radius: 50%;
    border-top: 4px solid #f5dfad;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
