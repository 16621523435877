
/* The actual timeline (the vertical ruler) */

@media (max-width: 768px) {
  .angled-letter .prop, .angled-letter-a .prop, .angled-letter-r .prop{
    font-size: 5em; /* Adjust font size for smaller screens */
}
}
#proposal-tag {
  margin-top:20px;
  margin-right:-20px;
}
#proposal-location{
  margin-right:-20px;
}
.timeline::after {
    bottom: -5%;
  }
.center {
  display:block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

}

/* src/components/Proposal.css */

/* Masonry Grid */
.masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style the items */
.masonry-item {
  margin-bottom: 30px; /* space between items */
  width: 100%;
  object-fit: cover;
}


/* Default Masonry Grid Item Spacing */
.masonry-item {
  margin-bottom: 10px; /* Space between images (acts as row spacing) */
}

/* Masonry grid row-gap for larger screens */
.masonry-grid {
  display: flex;
  margin-left: -10px; /* Adjust margin for columns */
  row-gap: 10px; /* Default row spacing */
  width: auto;
}

.masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

/* Adjust Spacing for Medium Screens */
@media (max-width: 1100px) {
  .masonry-item {
      margin-bottom: 8px; /* Reduce space between rows */
  }

  .masonry-grid {
      margin-left: -8px;
      row-gap: 15px; /* Reduce row-gap for medium screens */
  }

  .masonry-grid_column {
      padding-left: 8px;
  }
}

/* Adjust Spacing for Smaller Screens */
@media (max-width: 700px) {
  .masonry-item {
      margin-bottom: 5px;
  }

  .masonry-grid {
      margin-left: -5px;
      row-gap: 10px; /* Reduce row-gap for small screens */
  }

  .masonry-grid_column {
      padding-left: 5px;
  }
}

/* Adjust Spacing for Very Small Screens */
@media (max-width: 500px) {
  .masonry-item {
      margin-bottom: 2px;
  }

  .masonry-grid {
      margin-left: -2px;
      row-gap: 5px; /* Further reduce row-gap for very small screens */
  }

  .masonry-grid_column {
      padding-left: 2px;
  }
}
