/* ---------------Heading------------ */
.fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure the header appears above other content */
  }
/* Sticky logo */
.sticky-logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  align-items: center; /* Align items vertically */
}

/* Sticky menu */
.sticky-menu {
  position: fixed;
  top: 0;
  right:-20px;
  z-index: 9999; /* Ensure the indicator is above other content */
  font-family: "Cinzel", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  padding: 10px 35px; /* Add padding */
  margin: 0; /* Remove any margin */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically (if needed) */
}

.sticky-menu ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically (if needed) */
  list-style: none;
}

.sticky-menu li {
  display: inline-block;
  margin-right: 20px;
}

.sticky-menu a {
  text-decoration: none;
  color: black; /* Adjust color as needed */
}
