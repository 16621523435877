
/*------------BIG DAY------------------*/
#big-day h1 {
    display: block;
    text-align: center;
    
}
.row-col-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  position: relative; /* To position the lines */
}


.wedding-timeline {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.timeline {
  position: relative;

}

.container {
  flex: 1;
  padding: 20px; /* Adjusted padding for less vertical space */
  position: relative;
  margin-bottom: -150px; /* Use negative margin to create overlap */
}

.container.left .content {
  margin-right: 20px;
  text-align: center;

}

.container.right .content {
  margin-left: 20px;
  text-align: center;

}
/* Place the container to the left */
.left {
  left: 57%;
}
/* Place the container to the right */
.right {
  right: 57%;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: black;
  top: 10%;
  bottom: -25%;
  left: 50%;
  z-index: 1;
}

/* Add arrows to the left container (pointing right) */
.container.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 1;
  right: 100%;
  border: medium solid black;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent black;
  text-align: left;
}

/* Add arrows to the right container (pointing left) */
.container.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 50%;
  width: 0;
  z-index: 1;
  left: 100%;
  border: medium solid black;
  border-width: 10px 10px 10px 0;
  border-color: transparent black transparent transparent;
}



/* Setting aspect ratio and matching the height of the timeline */
.bordered-img {
  width: 100%; /* Automatically adjusts width */
  height: 100%; /* Matches the container's height */
  aspect-ratio: 1/1.2; /* Forcing a square aspect ratio, adjust if necessary */
  object-fit: cover; /* Ensures the image fills the area while maintaining its aspect ratio */
}

.sketch-border {
  padding: 20px;
  border-radius: 2px;        /* Slight rounding for a hand-drawn look */
  background-color: #f2ebe2; /* Light beige background for aesthetics */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  overflow: hidden;
  object-fit: cover;
}

.sketch-border p{
  margin: 6px;
}
.sketch-border::before{
  content: '';
  object-fit: cover;
  position: absolute;
  width: 47%;
  height: 94%;
  border: 1px dashed #444;  /* Double border effect */
  transform: translate(4px, 4px); /* Slight misalignment for sketch feel */
  pointer-events: none;
}

/* Responsive font size */
@media (max-width: 768px) {
  .container p {
      font-size: .83em; /* Adjust font size for smaller screens */
  }

.row-col-box {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}
  
 .bigdayformat .a {
    display: grid;
    gap: 15px;
    position: relative; /* To position the lines */
    grid-row: 1;
  }
 .bigdayformat .b {
    margin-top:10px;
    grid-row: 2;
    margin-top: 25%;
  }
 .sketch-border::before{
    margin-top: 0%;
    height: 39%;
    left: 2%;
    width: 94%;
  }
}



@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}