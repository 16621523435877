#ourstory{
  margin-top:-20px;
}
#ourstory-tag {
  text-align:right;
  margin-top:0px;
  margin-right:-50px;
}

#ourstory-location{
  text-align:right;
  margin-right:-50px;
}

.ourstoryformat {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0px; /* Adjust spacing as needed */
}
.ourstoryformat .a{
  flex: 1; /* Text section */
  margin-left: 50px
}
.ourstoryformat img{
  flex: 1; /* Image section */
  width: 50%; /* Ensures image doesn’t exceed container */
}
@media (max-width: 768px) {
  #ourstory{
    margin-top:-60px;
  }
  #ourstory-tag {
    margin-top:20px;
    margin-right:0px;
  }
  #ourstory-location{
    margin-bottom:10px;
    margin-right:0px;
  }

  .ourstoryformat p {
    font-size: 1em;
  }
  
  .ourstoryformat {
      flex-direction: column;
      align-items: center; /* Centers content for smaller screens */
  }

  .ourstoryformat .a{
    margin-left: 10px
  }
  .ourstoryformat img {
      margin-top: 20px; /* Space between text and image */
      width: 120%; /* Adjust size as needed */
      margin-left:-25px;
  }

}


.center {
  display:block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

}

/* Default Masonry Grid Item Spacing */
.masonry-item {
  margin-bottom: 10px; /* Space between images (acts as row spacing) */
}

/* Masonry grid row-gap for larger screens */
.masonry-grid {
  display: flex;
  margin-left: -10px; /* Adjust margin for columns */
  row-gap: 10px; /* Default row spacing */
  width: auto;
}

.masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

/* Adjust Spacing for Medium Screens */
@media (max-width: 1100px) {
  .masonry-item {
      margin-bottom: 8px; /* Reduce space between rows */
  }

  .masonry-grid {
      margin-left: -8px;
      row-gap: 15px; /* Reduce row-gap for medium screens */
  }

  .masonry-grid_column {
      padding-left: 8px;
  }
}

/* Adjust Spacing for Smaller Screens */
@media (max-width: 700px) {
  .masonry-item {
      margin-bottom: 5px;
  }

  .masonry-grid {
      margin-left: -5px;
      row-gap: 10px; /* Reduce row-gap for small screens */
  }

  .masonry-grid_column {
      padding-left: 5px;
  }
}

/* Adjust Spacing for Very Small Screens */
@media (max-width: 500px) {
  .masonry-item {
      margin-bottom: 2px;
  }

  .masonry-grid {
      row-gap: 5px; /* Further reduce row-gap for very small screens */
  }

  .masonry-grid_column {
      padding-left: 5px;
  }
}
