
/*------------ Travel ------------------*/
#iframe-container {
    height: 480px; /* Set a fixed height for the container */
    width: 100%;
    overflow: hidden; /* Hide overflow content */
    position: relative; /* Required for absolute positioning */

  }
  #lazy-iframe {
    position: absolute; /* Position iframe absolutely */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 25px; /* Adjust the bottom margin as needed */
  }
  h1 {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .apple-guide-button {
    display: inline-block;
    background-color: #ff4e50; /* Bright, cute color */
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 50px; /* Rounded button */
    text-align: center;
    align-items: center;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 0; /* No extra space between button and text */
    margin-bottom: 0; /* No extra space below button */
  }
  
  .apple-guide-button:hover {
    background-color: #ff2d3a; /* Slightly darker shade on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
  }
  
  .apple-guide-button:active {
    background-color: #e81f29; /* Darker shade when clicked */
  }
  #button-container {
    display: flex;
    justify-content: center; /* Horizontally center the button */
    align-items: center;     /* Vertically center the button */
    margin-top: 20px;        /* Optional: add space between the button and recommendations text */
  }
  
  #guide-button {
    background-color: #FF6F61; /* Custom background color */
    color: white;             /* Text color */
    padding: 10px 20px;       /* Padding for the button */
    font-size: 16px;          /* Font size */
    border: none;             /* Remove default border */
    border-radius: 5px;       /* Rounded corners */
    cursor: pointer;         /* Pointer cursor on hover */
    text-decoration: none;    /* Remove underline */
    display: inline-block;
  }
  
  #guide-button:hover {
    background-color: #D55C50; /* Darker shade on hover */
  }
  @media (max-width: 500px) {
    #Travel {
        display: flex;
    }
  }
  #button-container img {
    width: 100px; /* Adjust size as needed */
    height: auto;
    cursor: pointer;
    transition: transform 0.2s ease;
    animation: pulse 1.5s infinite ease-in-out; /* Pulse animation */
  }
  
  #button-container img:hover {
    transform: scale(1.1); /* Slight zoom-in effect on hover */
    animation: none; /* Pause the pulse animation on hover for better effect */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  