
/*---------------IN BETWEEN SECTION ------------------*/
.background-image-size1 {
    width: 100vw; /* Occupy full width of the container */
    height: auto; /* Set the height to 200vh (2 times the viewport height) */
    object-fit: cover; /* Ensure the image covers the entire container, cropping if necessary */
    z-index: -1;
  }
  .background-image-size1 img {
    width: 100vw; /* Occupy full width of the container */
    min-height: 100vh; /* Set the height to 200vh (2 times the viewport height) */
    
    object-fit: cover; /* Ensure the image covers the entire container, cropping if necessary */
    z-index: -1;
  }


/* --------------FAQ Section-------------- */
.faq-container {
    align-items: center; /* Vertically center */
    background-color: #e0f2f1; /* Set background color */
    padding: 20px; /* Add padding */
    max-width: 370px; /* Set maximum width */
    margin: 0 auto; /* Center horizontally */
    z-index: 4;
    
}

/* Reduce margin for h2 within .faq-container */
.faq-container h2 {
  margin: 10px 0; /* Adjust margin as needed */
}

/* FAQ item styling */
.faq-item {
    margin-bottom: 20px; /* Add margin */
    cursor: pointer; /* Set cursor */
    z-index: 1;
    pointer-events: auto;
    
}

.faq-item.active .question {
  color: #555;

}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  gap: 1rem;
}
.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  font-size: 1em;
  text-align: center;
  color: #555;
}
.answer p{
  color: #555;
}
.answer a:link {
  color: rgb(63, 63, 214); /* Unvisited link */
}

.answer a:visited {
  color: #9b59b6; /* Visited link */
}

.answer a:hover {
  color: green; /* On hover */
}

