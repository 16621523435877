
#engagement-text1{
    margin-top: 50px;  
}
@media (max-width: 768px) {
    #engagement-text{
        left: 40%;
        
    }
    #engagement-text1{
        text-align: right;
        margin-right:-10%;
        margin-top: 20px;
    }
}


/* Default Masonry Grid Item Spacing */
.masonry-item {
    margin-bottom: 10px; /* Space between images (acts as row spacing) */
  }
  
  /* Masonry grid row-gap for larger screens */
  .masonry-grid {
    display: flex;
    margin-left: -10px; /* Adjust margin for columns */
    row-gap: 10px; /* Default row spacing */
    width: auto;
  }
  
  .masonry-grid_column {
    padding-left: 10px;
    background-clip: padding-box;
  }
  
  /* Adjust Spacing for Medium Screens */
  @media (max-width: 1100px) {
    .masonry-item {
        margin-bottom: 8px; /* Reduce space between rows */
    }
  
    .masonry-grid {
        margin-left: -8px;
        row-gap: 15px; /* Reduce row-gap for medium screens */
    }
  
    .masonry-grid_column {
        padding-left: 8px;
    }
  }
  
  /* Adjust Spacing for Smaller Screens */
  @media (max-width: 700px) {
    .masonry-item {
        margin-bottom: 5px;
    }
  
    .masonry-grid {
        margin-left: -5px;
        row-gap: 10px; /* Reduce row-gap for small screens */
    }
  
    .masonry-grid_column {
        padding-left: 5px;
    }
  }
  
  /* Adjust Spacing for Very Small Screens */
  @media (max-width: 500px) {
    .masonry-item {
        margin-bottom: 2px;
    }
  
    .masonry-grid {
        row-gap: 5px; /* Further reduce row-gap for very small screens */
    }
  
    .masonry-grid_column {
        padding-left: 5px;
    }
  }
  